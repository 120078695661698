import React, { useRef, useEffect } from "react";
import "./SectionFourMobile.css";
import { useInViewport } from "react-in-viewport";
function SectionFourMobile() {
  const paraRef = useRef(null) 

  const {inViewport: paraVisible} = useInViewport(paraRef, {}, { disconnectOnLeave: false })
  useEffect(()=>{

    if(paraVisible){
      if(paraRef){
        paraRef.current.style.animationName = "come-up-mobile-ani"
        paraRef.current.style.animationDelay = "0.5s"
        paraRef.current.style.animationDuration = "1s"
        paraRef.current.style.animationFillMode = "forwards"
        paraRef.current.style.animationTimingFunction = "ease-in-out"
      }
    }else{
      if(paraRef){
        paraRef.current.style.opacity = "0"
      }
    }

  }, [paraVisible])
  return (
    <div id="section-four-mobile" className="section-four-mobile container-fluid d-flex justify-content-center align-items-center">
     
      <div className="container">
        <p ref={paraRef} className="text-center section-four-paragraph-mobile">
        Whether you're a painter, a sculptor, a woodworker, or any other kind of creator, your story deserves to be heard, your craft deserves to be <span className="bold-text">seen</span>.
        </p>
       
      </div>
    </div>
  );
}

export default SectionFourMobile;
