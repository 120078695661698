import React, { useRef, useEffect } from "react";
import "./SectionThreeMobile.css";
import { useInViewport } from "react-in-viewport";
function SectionThreeMobile() {
  const imageRef = useRef(null) 

  const {inViewport: imageVisible} = useInViewport(imageRef, {}, { disconnectOnLeave: false })
  useEffect(()=>{

    if(imageVisible){
      if(imageRef){
        imageRef.current.style.animationName = "fade-in-mobile-ani"
        imageRef.current.style.animationDelay = "0.5s"
        imageRef.current.style.animationDuration = "1s"
        imageRef.current.style.animationFillMode = "forwards"
        imageRef.current.style.animationTimingFunction = "ease-in-out"
      }
    }else{
      if(imageRef){
        imageRef.current.style.opacity = "0"
      }
    }

  }, [imageVisible])



  return (
    <div id="section-three-mobile" className="section-three-mobile container-fluid d-flex justify-content-center align-items-center">
   
      <div className="container text-center">
        <div className="row">
          <img ref={imageRef} className="section-three-image-mobile img-fluid" src="/image/image.svg" alt=""/>
       
        </div>
      </div>
    </div>
  );
}

export default SectionThreeMobile;
