import React, { useEffect } from "react";
import "./SectionOneMobile.css";

function SectionOneMobile() {

  useEffect(() => {
    const m_video = document.getElementById("mobile-video");

    const playVideo = () => {
      var playPromise = m_video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback started
          })
          .catch(error => {
            // Playback failed - show fallback UI
            m_video.setAttribute("controls", "controls");
          });
      }
    };

    playVideo();

    // For iOS devices: add event listener to ensure the video can be played if autoplay fails
    m_video.addEventListener("click", playVideo);

    return () => m_video.removeEventListener("click", playVideo);
  }, []);

  return (
    <div id="section-one-mobile" className="section-one-mobile">
      <img className="section-one-logo-mobile" src="/image/Logo.svg" alt="" />
      <div className="container section-one-video-mobile">
        <video id="mobile-video" loop muted playsinline width="100%">
          <source src="video/exyst-video.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default SectionOneMobile;