import React, { useContext, useEffect } from "react";
import "./SectionFive.css";
import { NavContext } from "../../App";
function SectionFive() {
  const {index, setIndex} = useContext(NavContext)
  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if(event.deltaY > 0){
        var fiveAqdas = document.getElementById("section-five-container")
        var fiveAqdasImage = document.getElementById("section-five-image")
     
        if(fiveAqdas){
        fiveAqdas.style.animationName = "section-five-container-up-ani"
        fiveAqdas.style.animationDuration = "1.2s"
        fiveAqdas.style.animationDelay = "0s"
        fiveAqdas.style.opacity = "1"
        }
        if(fiveAqdasImage){
        fiveAqdasImage.style.animationName = "section-five-image-left-ani"
        fiveAqdasImage.style.animationDuration = "1.2s"
        fiveAqdasImage.style.animationDelay = "0s"
        fiveAqdasImage.style.opacity = "1"
        }
        setTimeout(function() {
          setIndex(5)
          setTimeout(function() {
            if (fiveAqdas) {
              fiveAqdas.style.animationName = ""; // Reset the animation name
               fiveAqdas.style.opacity = "1"
          }
            if (fiveAqdasImage) {
              fiveAqdasImage.style.animationName = ""; // Reset the animation name
               fiveAqdasImage.style.opacity = "1"
          }
        }, 500); // Adjust the delay as needed
        }, 500);
      }else{
        setIndex(3)
      }
    }
  };
  useEffect(() => {
    const sectionFiveElement = document.getElementById("section-five");
    if (sectionFiveElement) {
      sectionFiveElement.addEventListener("wheel", handleWheel);
      
    }

    return () => {
      console.log("removed 3");
      if (sectionFiveElement) {
        sectionFiveElement.removeEventListener("wheel", handleWheel);
     
      }
    };
  }, [index]);
  return (
    <div id="section-five" className="section-five container-fluid d-flex justify-content-center align-items-center">
        <img className="section-two-logo" src="/image/Logo.svg" alt="" />
        <img id="section-five-image" className="section-five-image" style={{zIndex: "1"}} src="/image/section-five-image.svg" alt="" />
        <div id="section-five-container" className="container section-five-container text-center" style={{zIndex: "2"}}>
          <div className="row">
            <div className="col-12">
            <img src="/image/section-five-heading.svg" className="img-fluid" alt="" />
            </div>
            <div className="col-12 mt-4">
            <button className="btn section-five-button" onClick={()=>setIndex(5)}>Submission Form</button>
            </div>
          </div>
        </div>
      
    </div>
  );
}

export default SectionFive;
