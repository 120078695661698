import React, { useRef, useEffect } from "react";
import "./SectionTwoMobile.css";
import { NavContext } from "../../App";
import { useInViewport } from "react-in-viewport";
function SectionTwoMobile() {

  const paraOneRef = useRef(null) 
  const paraTwoRef = useRef(null) 
  const paraThreeRef = useRef(null)
  
  const {inViewport: paraOneVisible} = useInViewport(paraOneRef, {}, { disconnectOnLeave: false })
  const {inViewport: paraTwoVisible} = useInViewport(paraTwoRef, {}, { disconnectOnLeave: false })
  const {inViewport: paraThreeVisible} = useInViewport(paraThreeRef, {}, { disconnectOnLeave: false })

  useEffect(()=>{

    if(paraOneVisible){
      if(paraOneRef){
        paraOneRef.current.style.animationName = "come-up-mobile-ani"
        paraOneRef.current.style.animationDelay = "0.5s"
        paraOneRef.current.style.animationDuration = "1s"
        paraOneRef.current.style.animationFillMode = "forwards"
        paraOneRef.current.style.animationTimingFunction = "ease-in-out"
      }
    }else{
      if(paraOneRef){
        paraOneRef.current.style.opacity = "0"
      }
    }
    if(paraTwoVisible){
      if(paraTwoRef){
        paraTwoRef.current.style.animationName = "come-up-mobile-ani"
        paraTwoRef.current.style.animationDelay = "0.5s"
        paraTwoRef.current.style.animationDuration = "1s"
        paraTwoRef.current.style.animationFillMode = "forwards"
        paraTwoRef.current.style.animationTimingFunction = "ease-in-out"
      }
    }else{
      if(paraTwoRef){
        paraTwoRef.current.style.opacity = "0"
      }
    }
    if(paraThreeVisible){

        if(paraThreeRef){
          paraThreeRef.current.style.animationName = "come-up-mobile-ani"
          paraThreeRef.current.style.animationDelay = "0.5s"
          paraThreeRef.current.style.animationDuration = "1s"
          paraThreeRef.current.style.animationFillMode = "forwards"
          paraThreeRef.current.style.animationTimingFunction = "ease-in-out"
        }
    }else{
      if(paraThreeRef){
        paraThreeRef.current.style.opacity = "0"
      }
    }
  }, [paraOneVisible, paraTwoVisible, paraThreeVisible])


  return (
    <div id="section-two-mobile" className="section-two-mobile container-fluid d-flex justify-content-center align-items-center">
 
      <div  className="container">
        <p ref={paraOneRef} className="text-center section-two-paragraph-mobile">
          In every brushstroke, every carving, every thread woven with care lies
          a story waiting to be told At <span className="bold-text">Exyst,</span> we believe in the power of these
          stories.
        </p>
        <p ref={paraTwoRef} className="text-center section-two-paragraph-mobile mt-3">
          That's why we're here to champion our community—artists, craftsmen,
          and creators who pour their hearts into their work but haven't had the
          chance to share their tale. Life's responsibilities, financial
          constraints, or simply the lack of a platform have kept these stories
          untold. But we believe everyone deserves a stage, an audience, and a
          chance to share their work with the <span className="bold-text">world.</span>
        </p>
        <p ref={paraThreeRef} className="text-center  section-two-paragraph-mobile mt-3">
          Introducing <span className="bold-text">Exyst</span> — a space for you to Express Your Story. Our
          platform is not just a marketplace; it's a community that celebrates
          creativity, craftsmanship, and individuality. At <span className="bold-text">Exyst,</span> we empower you
          to share your art, connect with like-minded individuals, and reach a <span className="bold-text">global audience.</span>
        </p>
      </div>
    </div>
  );
}

export default SectionTwoMobile;
