import React, { useContext, useEffect } from "react";
import "./SectionOne.css";
import { NavContext } from "../../App";

function SectionOne() {
  const { index, setIndex } = useContext(NavContext);
  const handleWheel = (event) => {
    if (event.deltaY !== 0) {
      // debouncedChangeIndex(index + (event.deltaY > 0 ? 1 : -1));
      if (event.deltaY > 0) {
        var muhammadAqdas = document.getElementById("section-one-video");

        if (muhammadAqdas) {
          muhammadAqdas.style.animationName = "section-one-video-up-ani";
          muhammadAqdas.style.animationDuration = "1.2s";
          muhammadAqdas.style.animationDelay = "0s";
          muhammadAqdas.style.opacity = "1";
        }
      
        setTimeout(function () {
          setIndex(1);
          setTimeout(function () {
            if (muhammadAqdas) {
              muhammadAqdas.style.animationName = ""; // Reset the animation name
              muhammadAqdas.style.opacity = "1";
              const d_video = document.getElementById("desktop-video");
              if (d_video) {
                d_video.pause();
                d_video.currentTime = 0; // Resets current time to 0
              }
            }
          }, 500); // Adjust the delay as needed
        }, 500);
      } else {
        setIndex(0);
      }
    }
  };
  useEffect(() => {
    const sectionOneElement = document.getElementById("section-one");
    if (sectionOneElement) {
      sectionOneElement.addEventListener("wheel", handleWheel);
    }

    return () => {
      console.log("removed 3");
      if (sectionOneElement) {
        sectionOneElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, [index]);

  useEffect(() => {
    const d_video = document.getElementById("desktop-video");
  // Show loading animation.
  var playPromise = d_video.play();

  if (playPromise !== undefined) {
    playPromise.then(_ => {
      // Automatic playback started!
      // Show playing UI.
    })
    .catch(error => {
      // Auto-play was prevented
      // Show paused UI.
    });
  }
  }, [index]);
  return (
    <div id="section-one" className="section-one">
      <img
        id="section-one-logo"
        className="section-one-logo"
        src="/image/Logo.svg"
        alt=""
      />
      <div id="section-one-video" className="container section-one-video">
        <video id="desktop-video" loop muted width="100%" height="100%">
          <source src="video/exyst-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default SectionOne;
